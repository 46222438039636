<template>
  <BaseInput
    :value="values[field.id]"
    size="is-small"
    type="text"
    :disabled="field.properties.basic.isDisabled"
    :placeholder="field.properties.basic.hasPlaceholder ? getTranslation(`${field.id}_placeholder`, field.properties.basic.placeholder) : ''"
    :mask="ibanMask"
    :loading="isLoading"
    @input="handleUpdate($event)"
    v-on="fieldEvents"
  />
</template>

<script >
import axios from 'axios';
import { debounce } from 'lodash';
import BaseInput from './generics/BaseInput.vue';
import { useLocalizationStore } from '@/store/localizationStore';
import { onMounted, ref } from '@vue/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  },
  values: {
    type: Object,
    required: true
  },
  errors: {
    type: Object,
    required: true
  },
  fieldEvents: {
    type: Object,
    default: () => ({})
  },
  customEventHandler: {
    type: Function,
    default: () => {}
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const localizationStore = useLocalizationStore();
  const {
    getTranslation
  } = localizationStore;
  const ibanMask = {
    blocks: [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
    uppercase: true
  };
  const isLoading = ref(false);
  const handleUpdate = value => {
    handleIBANValidation(value);
    emit('update', {
      value: value?.toUpperCase()
    });
    if (props.customEventHandler) {
      props.customEventHandler();
    }
  };
  const handleIBANValidation = value => {
    if (props.field.properties.basic.hasIbanApiValidation) {
      isLoading.value = true;
      emit('loading', isLoading.value);
      checkIBAN(value);
    }
  };
  const checkIBAN = debounce(async value => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${window.env.VUE_APP_APP_SERVICE_DOMAIN}/iban`,
        params: {
          iban: value
        }
      });
      if (!response.data.is_valid) {
        emit('error', {
          message: props.field?.properties?.validation?.message ?? 'Bitte geben Sie eine gültige IBAN an'
        });
      } else {
        emit('success', {
          message: ''
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      isLoading.value = false;
      emit('loading', isLoading.value);
    }
  }, 500);
  onMounted(() => {
    const fieldValue = props.values[props.field.id];
    if (fieldValue) {
      handleIBANValidation(fieldValue);
    }
  });
  return {
    getTranslation,
    ibanMask,
    isLoading,
    handleUpdate
  };
};
__sfc_main.components = Object.assign({
  BaseInput
}, __sfc_main.components);
export default __sfc_main;
</script>
